<template>
    <DnButton
        class="base-button"
        :class="classes"
    >
        <span class="button__icon">
            <slot name="icon-left" />
        </span>

        <slot />

        <span class="button__icon">
            <slot name="icon-right" />
        </span>
    </DnButton>
</template>

<script setup>
import DnButton from '@digitalnatives/button';

const slots = useSlots();

const classes = computed(() => {
    if (slots?.['icon-left']) {
        return 'button--icon-left';
    } else if (slots?.['icon-right']) {
        return 'button--icon-right';
    }

    return '';
});
</script>

<style src="./BaseButton.less" lang="less" scoped />
